import { template as template_c79754617c3b4bc7b716795be60ea8f4 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import TopicParticipant from "discourse/components/topic-map/topic-participant";
export default class TopicParticipants extends Component {
    toggledUsers = new Set(this.args.userFilters);
    static{
        template_c79754617c3b4bc7b716795be60ea8f4(`
    {{#if @title}}
      <h3>{{@title}}</h3>
    {{/if}}
    <div class="topic-map__users-list {{unless @title '--users-summary'}}">
      {{#each @participants as |participant|}}
        <TopicParticipant
          @participant={{participant}}
          @toggledUsers={{this.toggledUsers}}
        />
      {{/each}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
