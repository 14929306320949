import { template as template_d57c3f70212a49dbb817e7b3dfa759b4 } from "@ember/template-compiler";
import { concat, hash } from "@ember/helper";
import { LinkTo } from "@ember/routing";
import i18n from "discourse-common/helpers/i18n";
const GroupActivityFilter = template_d57c3f70212a49dbb817e7b3dfa759b4(`
  <li>
    <LinkTo
      @route={{concat "group.activity." @filter}}
      @query={{hash category_id=@categoryId}}
    >
      {{i18n (concat "groups." @filter)}}
    </LinkTo>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GroupActivityFilter;
